import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {destroy as destroySession} from "app/requests/session";
import {setUser} from "app/actions/user";
import {update as updateUser} from "app/requests/user";
import Sidebar from "components/layout/sidebar";
import {usePage} from "lib/hooks";
import useAppContext from "lib/hooks/use-app-context";

export default function Profile() {
  const {dispatch, user} = useAppContext();
  const [changes, setChanges] = useState({});
  const navigate = useNavigate();

  usePage({heading: "Settings"});
  useEffect(() => {
    if(!user) { navigate("/subscribe"); }
  }, [user]);

  if(!user) { return null; }

  const onChange = ({target: {checked, name, type, value}}) => (
    setChanges({...changes, [name]: type === "checkbox" ? checked : value})
  );
  const onSubmit = (e) => {
    e.preventDefault();

    if(Object.keys(changes).length === 0) {
      return dispatch(createNotification({
        content: "Please make changes before submitting",
        type: "danger"
      }));
    }

    updateUser({user: changes}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "success" : "danger"
      }));

      if(data.success) {
        dispatch(setUser(data.user));
        setChanges({});
      }
    });
  };
  const signOut = () => {
    destroySession().then((data) => {
      if(data.success) {
        window.location.href = `/subscribe?message=${data.message}&type=success`;
      } else {
        dispatch(createNotification({content: data.message, type: "danger"}));
      }
    });
  };
  const value = (name) => (
    changes[name] === undefined ? user[name] : changes[name]
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-9">
          <div className="row mt-3">
            <div className="col-lg-6">
              <form onSubmit={onSubmit}>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <label className="input-group-text" htmlFor="user-email">Email</label>
                  </div>
                  <input
                    className="form-control py-4"
                    id="user-email"
                    name="email"
                    onChange={onChange}
                    placeholder="email"
                    required={true}
                    type="email"
                    value={value("email") || ""}
                  />
                </div>
                <div className="custom-control custom-switch mb-4">
                  <input
                    checked={"post_notifications" in changes ? changes.post_notifications : user.post_notifications}
                    className="custom-control-input"
                    id="user-post-notifications"
                    name="post_notifications"
                    onChange={onChange}
                    type="checkbox"
                  />
                  <label className="custom-control-label" htmlFor="user-post-notifications">Episode Notifications</label>
                </div>
                <div className="custom-control custom-switch mb-4">
                  <input
                    checked={"newsletter_notifications" in changes ? changes.newsletter_notifications : user.newsletter_notifications}
                    className="custom-control-input"
                    id="user-newsletter-notifications"
                    name="newsletter_notifications"
                    onChange={onChange}
                    type="checkbox"
                  />
                  <label className="custom-control-label" htmlFor="user-newsletter-notifications">Newsletter Notifications</label>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary px-5">Save Settings</button>
                  <button className="btn btn-link" onClick={signOut} type="button">Sign Out</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <Sidebar />
        </div>
      </div>
    </div>
  );
}
