import {useEffect, useState} from "react";
import {getAll} from "app/requests/contributers";
import {ActionText} from "components/helpers";
import Sidebar from "components/layout/sidebar";
import {Loading} from "components/pages";
import {usePage} from "lib/hooks";

export default function About() {
  const [contributers, setContributers] = useState(null);

  usePage({heading: "About"});
  useEffect(() => {
    getAll().then((data) => setContributers(data.contributers));
  }, []);

  if(!contributers) { return <Loading />; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-9">
          <h2 className="mb-5">The Visible Voices Podcast amplifies voices visible and voices invisible</h2>
          {contributers.map((contributer) => (
            <div key={contributer.name} className="row mt-5">
              <div className="col-md-4">
                {contributer.image && <img alt={contributer.name} className="img-fluid" src={contributer.image} />}
              </div>
              <div className="col-md-8">
                <h1 className="mt-1 text-uppercase">{contributer.name}</h1>
                <div className="small text-accent text-uppercase">{contributer.title}</div>
                {contributer.website && <a className="h6 text-underline" href={contributer.website} rel="noreferrer" target="_blank">{contributer.website.replace("http://", "").replace("https://", "")}</a>}
                <ActionText.Content className="mt-4" html={contributer.bio} />
              </div>
            </div>
          ))}
        </div>
        <div className="col-lg-3">
          <Sidebar />
        </div>
      </div>
    </div>
  );
}
