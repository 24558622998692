import Credits from "components/layout/credits";
import useAppContext from "lib/hooks/use-app-context";

export default function Footer() {
  const {page} = useAppContext();
  const classes = ["container-fluid", "mt-auto"];

  if(page.sidebar) { classes.push("d-block d-lg-none"); }

  return (
    <div className={classes.join(" ")}>
      <div className="row mt-3">
        <div className="col-lg-3 offset-lg-9">
          <Credits />
        </div>
      </div>
    </div>
  );
}
