import {useMemo, useReducer} from "react";
import {RouterProvider} from "react-router-dom";
import {Context, Reducer, createInitialState} from "app";
import createRouter from "app/router";

export default function Application(props) {
  const [reducer, dispatch] = useReducer(Reducer, props, createInitialState);
  const state = useMemo(() => ({...reducer, dispatch}), [reducer, dispatch]);
  const router = useMemo(() => createRouter(state), [!!state.user]);

  return (
    <Context.Provider value={state}>
      <RouterProvider router={router} />
    </Context.Provider>
  );
}
