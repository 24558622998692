import {useLayoutEffect} from "react";
import {useLocation, Outlet} from "react-router-dom";
import AdminNavbar from "components/layout/admin/navbar";
import Analytics from "components/layout/analytics";
import ErrorBoundary from "components/layout/error-boundary";
import Footer from "components/layout/footer";
import Notifications from "components/layout/notifications";
import Navbar from "components/layout/public/navbar";
import SubscribeModal from "components/helpers/subscribe-modal";
import useAppContext from "lib/hooks/use-app-context";

export default function Container() {
  const {user} = useAppContext();
  const location = useLocation();
  const admin = user && user.admin;

  useLayoutEffect(() => { window.scrollTo(0, 0); }, [location.pathname]);

  return (
    <div className="app">
      {admin && <AdminNavbar />}
      <Analytics />
      <Navbar />
      <Notifications />
      <SubscribeModal />
      <div className="page d-flex flex-column">
        <main>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </main>
        <Footer />
      </div>
    </div>
  );
}
