import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {setSidebar} from "app/actions/page";
import appleImage from "app/assets/images/apple.png";
import spotifyImage from "app/assets/images/spotify.png";
import youtubeImage from "app/assets/images/youtube.png";
import {ShareButtons} from "components/helpers";
import Credits from "components/layout/credits";
import useAppContext from "lib/hooks/use-app-context";
import useContent from "lib/hooks/use-content";

function Sidebar({children = null}) {
  const {channel, dispatch, user} = useAppContext();
  const youtubeLink = useContent("Social", "Youtube");
  const [showShare, setShowShare] = useState(false);
  const shareTitle = document.querySelector("meta[property='og:title']").content;
  const shareUrl = window.location.href;
  const share = () => setShowShare(!showShare);

  useEffect(() => {
    dispatch(setSidebar(true));

    return () => dispatch(setSidebar(false));
  }, []);

  return (
    <div className="sidebar d-flex flex-column h-100">
      <hr className="d-block d-sm-none" />
      <div>
        {!user && (
          <>
            <a className="sidebar-link" href="/subscribe">Subscribe</a>
            <br />
          </>
        )}
        <a className="sidebar-link" href="/episodes">All Podcasts</a>
        <br />
        <button className="sidebar-link" onClick={share} type="button">Share</button>
        <div className="share-space">
          {showShare && <ShareButtons title={shareTitle} url={shareUrl} />}
        </div>
      </div>
      {channel && (channel.spotify_link || channel.itunes_link) && (
        <div className="store-links mt-3">
          {channel.itunes_link && (
            <a className="itunes" href={channel.itunes_link} rel="noopener noreferrer" target="_blank">
              <img alt="Listen on Apple" width="200" src={appleImage} />
            </a>
          )}
          {channel.spotify_link && (
            <a className="spotify" href={channel.spotify_link} rel="noopener noreferrer" target="_blank">
              <img alt="Listen on Spotify" width="200" src={spotifyImage} />
            </a>
          )}
          {youtubeLink && (
            <a className="spotify" href={`https://www.youtube.com/@${youtubeLink}`} rel="noopener noreferrer" target="_blank">
              <img alt="Listen on Youtube" width="200" src={youtubeImage} />
            </a>
          )}
        </div>
      )}
      {children}
      <div className="d-none d-lg-block mt-auto">
        <Credits />
      </div>
    </div>
  );
}

Sidebar.propTypes = {children: PropTypes.node};

export default Sidebar;
