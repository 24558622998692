import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getAll} from "app/requests/posts";
import Pagination from "components/helpers/pagination";
import Sidebar from "components/layout/sidebar";
import {Loading} from "components/pages";
import {usePage} from "lib/hooks";
import {withoutBlankValues} from "lib/object";
import {displayDate} from "lib/string";

function PostsList({getPosts = getAll, prefix = ""}) {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(params.page ? +params.page : 1);

  usePage({heading: "Blog"});
  useEffect(() => {
    setLoading(true);
    getPosts(withoutBlankValues({page: pageNumber, tag: params.tag})).then((data) => {
      setPage({...data});
      setLoading(false);
    });
  }, [pageNumber]);

  if(!page.records || loading) { return <Loading />; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-9">
          {params.tag && (
            <p>
              <span>Showing results for </span>
              <a className="m-1" href={`${prefix}/blog/tags/${params.tag}`}>{params.tag}</a>
              <a href={`${prefix}/blog/posts`}>
                <i className="fas fa-times-circle" />
              </a>
            </p>
          )}
          {page.records.length === 0 && (
            <p>There are no posts{params.tag && " for this tag"}</p>
          )}
          <div className="row">
            {page.records.map((record) => (
              <div key={record.uid} className="col-md-4 my-2">
                <div className="font-weight-bold extra-small text-muted text-uppercase">
                  Published on {displayDate(record.published_at)}
                </div>
                <h3 className="mt-0">
                  <a className="text-black" href={`${prefix}/blog/posts/${record.uid}`}>{record.title}</a>
                </h3>
                <div className="font-weight-light letter-spacing-wide text-muted text-uppercase">{record.author}</div>
                {(record.tags.length > 0) && (
                  <div className="my-2">
                    {record.tags.map((_tag) => (
                      <a key={_tag} className="extra-small mr-2 text-uppercase" href={`${prefix}/blog/tags/${_tag}`}>{_tag}</a>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="text-center">
            <Pagination page={page} updatePage={setPageNumber} />
          </div>
        </div>
        <div className="col-lg-3">
          <Sidebar />
        </div>
      </div>
    </div>
  );
}

PostsList.propTypes = {
  getPosts: PropTypes.func,
  prefix: PropTypes.string
};

export default PostsList;
