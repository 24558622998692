import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Sidebar from "components/layout/sidebar";
import {usePage} from "lib/hooks";
import useAppContext from "lib/hooks/use-app-context";
import User from "./user";

export default function Subscribe() {
  const {user} = useAppContext();
  const navigate = useNavigate();

  usePage({heading: "Subscribe"});
  useEffect(() => {
    if(user) { navigate("/profile"); }
  }, [user]);

  if(user) { return null; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-9">
          <div className="row mt-3">
            <div className="col-md-6">
              <User />
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <Sidebar />
        </div>
      </div>
    </div>
  );
}
