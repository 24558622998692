import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {forgotPassword} from "app/requests/session";
import Sidebar from "components/layout/sidebar";
import {usePage} from "lib/hooks";
import useAppContext from "lib/hooks/use-app-context";

export default function ForgotPassword() {
  const {dispatch, user} = useAppContext();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const onSubmit = (e) => {
    e.preventDefault();

    forgotPassword({email}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "success" : "danger"
      }));
    });
  };

  usePage({heading: "Forgot Password"});
  useEffect(() => {
    if(user) { navigate("/profile"); }
  }, [user]);

  if(user) { return null; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-9">
          <div className="row mt-3">
            <div className="col-lg-6">
              <form onSubmit={onSubmit}>
                <div className="input-group mb-4">
                  <input
                    className="form-control py-4"
                    id="session-email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="email"
                    required={true}
                    type="email"
                    value={email}
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary px-5">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <Sidebar />
        </div>
      </div>
    </div>
  );
}
