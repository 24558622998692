import {createBrowserRouter, redirect, Navigate} from "react-router-dom";
import Container from "components/layout/container";
import ErrorBoundary from "components/layout/router-error-boundary";
import * as Pages from "components/pages";

export default function createRouter(initialState) {
  const {user} = initialState;
  const requireAdmin = () => {
    if(!user) return redirect("/subscribe");
    if(!user.admin) return redirect("/profile");

    return null;
  };
  const requireNoUser = () => {
    if(user) return redirect("/profile");

    return null;
  };
  const requireUser = () => {
    if(!user) return redirect("/subscribe");

    return null;
  };

  return createBrowserRouter(
    [{
      children: [
        {element: <Navigate to="/blog/posts" />, path: "/blog"},
        {element: <Navigate to="/" />, path: "/episodes"},
        {element: <Navigate to="/episodes" />, path: "/podcast"},
        ...[
          {Component: Pages.Public.Episodes.List, path: "/"},
          {Component: Pages.Public.About, path: "/about"},
          {Component: Pages.Public.Contact, path: "/contact"},
          {Component: Pages.Public.Disclaimer, path: "/disclaimer"},
          {Component: Pages.Public.Donate, path: "/donate"},
          {Component: Pages.Public.Blog.Posts.Show, path: "/blog/posts/:uid"},
          {Component: Pages.Public.Blog.Posts.List, path: "/blog/posts"},
          {Component: Pages.Public.Blog.Posts.List, path: "/blog/tags/:tag"},
          {Component: Pages.Public.Episodes.Transcript, path: "/episodes/:uid/transcript"},
          {Component: Pages.Public.Episodes.Show, path: "/episodes/:uid"},
          {Component: Pages.Public.Newsletters.Show, path: "/newsletters/:uid"},
          {Component: Pages.Public.Search, path: "/search"},
          {Component: Pages.Public.Transcripts, path: "/transcripts"},
          {Component: Pages.Public.Videos, path: "/videos"}
        ].map((route) => ({
          children: [route],
          path: route.path,
          Component: Pages.Public.Container
        })),
        ...[
          {Component: Pages.Public.User.ForgotPassword, loader: requireNoUser, path: "/forgot-password"},
          {Component: Pages.Public.User.Login, loader: requireNoUser, path: "/login"},
          {Component: Pages.Public.User.Profile, loader: requireUser, path: "/profile"},
          {Component: Pages.Public.User.Subscribe, loader: requireNoUser, path: "/subscribe"},
          {Component: Pages.Public.User.Unsubscribe, path: "/unsubscribe"}
        ].map((route) => ({
          children: [route],
          path: route.path,
          Component: Pages.Public.User.Container
        })),
        {element: <Navigate to="/admin/dashboard" />, loader: requireAdmin, path: "/admin"},
        {
          children: [
            {Component: Pages.Admin.Dashboard, path: "/admin/dashboard"},
            {Component: Pages.Admin.Channels.New, path: "/admin/channels/new"},
            {Component: Pages.Admin.Channels.Edit, path: "/admin/channels/:id"},
            {Component: Pages.Admin.Channels.List, path: "/admin/channels"},
            {Component: Pages.Admin.Contributers.New, path: "/admin/contributers/new"},
            {Component: Pages.Admin.Contributers.Edit, path: "/admin/contributers/:id"},
            {Component: Pages.Admin.Contributers.List, path: "/admin/contributers"},
            {Component: Pages.Admin.Episodes.New, path: "/admin/episodes/new"},
            {Component: Pages.Admin.Episodes.Edit, path: "/admin/episodes/:id"},
            {Component: Pages.Admin.Episodes.List, path: "/admin/episodes"},
            {Component: Pages.Admin.Messages.Show, path: "/admin/messages/:id"},
            {Component: Pages.Admin.Messages.List, path: "/admin/messages"},
            {Component: Pages.Admin.Newsletters.New, path: "/admin/newsletters/new"},
            {Component: Pages.Admin.Newsletters.Edit, path: "/admin/newsletters/:id"},
            {Component: Pages.Admin.Newsletters.List, path: "/admin/newsletters"},
            {Component: Pages.Admin.Posts.New, path: "/admin/posts/new"},
            {Component: Pages.Admin.Posts.Edit, path: "/admin/posts/:id"},
            {Component: Pages.Admin.Posts.List, path: "/admin/posts"},
            {Component: Pages.Admin.Sections.Edit, path: "/admin/sections/:id"},
            {Component: Pages.Admin.Sections.List, path: "/admin/sections"},
            {Component: Pages.Admin.Users.New, path: "/admin/users/new"},
            {Component: Pages.Admin.Users.Edit, path: "/admin/users/:id"},
            {Component: Pages.Admin.Users.List, path: "/admin/users"}
          ].map((route) => ({...route, loader: requireAdmin})),
          path: "/admin",
          Component: Pages.Admin.Container
        },
        {element: <Navigate to="/preview/episodes" />, loader: requireAdmin, path: "/preview"},
        {element: <Navigate to="/preview/blog/posts" />, loader: requireAdmin, path: "/preview/blog"},
        {element: <Navigate to="/preview/episodes" />, loader: requireAdmin, path: "/preview/podcast"},
        {
          children: [
            {Component: Pages.Preview.Blog.Posts.Show, path: "/preview/blog/posts/:uid"},
            {Component: Pages.Preview.Blog.Posts.List, path: "/preview/blog/posts"},
            {Component: Pages.Preview.Blog.Posts.List, path: "/preview/blog/tags/:tag"},
            {Component: Pages.Preview.Episodes.Transcript, path: "/preview/episodes/:uid/transcript"},
            {Component: Pages.Preview.Episodes.Show, path: "/preview/episodes/:uid"},
            {Component: Pages.Preview.Episodes.List, path: "/preview/episodes"},
            {Component: Pages.Preview.Transcripts, path: "/preview/transcripts"}
          ].map((route) => ({...route, loader: requireAdmin})),
          path: "/preview",
          Component: Pages.Preview.Container
        },
        {Component: Pages.NotFound}
      ],
      Component: Container,
      ErrorBoundary
    }]
  );
}
